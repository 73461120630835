import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import Sidebar from "../../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function BusinessLaw({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="Charleston South Carolina Business Attorneys | Tecklenburg, Jenkins & Jacoby, LLC"
        description="usiness success begins with the right business advice and
            representation. Under the direction of attorney Paul F. Tecklenburg our business law attorneys give you the advantage of having decades
            of transactional and litigation experience on your side."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/south-carolina-business-law/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>South Carolina Business Attorneys</h1>
          <h2>
            Attorneys With the Expertise to Handle Your Business Formation
          </h2>
          <p>
            Business success begins with the right business advice and
            representation. Under the direction of attorney{" "}
            <Link to="/attorneys/paul-f-tecklenburg">Paul F. Tecklenburg</Link>,
            our business law attorneys give you the advantage of having decades
            of transactional and litigation experience on your side. We
            represent our clients through every stage of business law, from
            choosing your legal entity to acquisition and mergers. Our dedicated
            business attorneys can guide you through all aspects of business
            formation such as:
          </p>
          <ul>
            <li>
              <h3>
                Articles of Organization for Limited Liability Companies
                (“LLC’s”)
              </h3>
            </li>
            <li>
              <h3>Operating Agreements for LLC’s</h3>
            </li>
            <li>
              <h3>Articles of Incorporation for Corporations</h3>
            </li>
            <li>
              <h3>Partnership Agreements</h3>
            </li>
            <li>
              <h3>Employment Contracts</h3>
            </li>
            <li>
              <h3>Mergers</h3>
            </li>
            <li>
              <h3>Asset Acquisitions</h3>
            </li>
          </ul>
          <h2>
            Experienced Legal Assistance with Complicated Business Transactions
          </h2>
          {/* <p>
            Business and commercial transactions are, by their very nature,
            complex. The challenge for an attorney is to manage all of the
            details without losing sight of the big picture – helping the
            clients obtain their business objectives in a cost-effective manner.
          </p> */}
          <p>
            Tecklenburg, Jenkins & Jacoby represent clients throughout South
            Carolina and the Southeast in all stages of their business and
            commercial transactions. Our attorneys diligently draft, negotiate,
            and confirm transaction details and business documents to assure
            that our clients’ interests are protected, and that each of their
            goals are met. The most critical business needs requiring an
            attorney from Tecklenburg, Jenkins & Jacoby include:
          </p>
          <ul>
            <li>
              <h3>Purchasing a Business</h3>
            </li>
            <li>
              <h3>Selling a Business</h3>
            </li>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-real-estate-law">
                  Real Estate Transactions
                </Link>
              </h3>
            </li>
            <li>
              <h3>Contract Drafting, Review And Negotiation</h3>
            </li>
            <li>
              <h3>Non-Competition Agreements</h3>
            </li>
            <li>
              <h3>Confidentiality Agreements</h3>
            </li>
            <li>
              <h3>Diligence Investigations</h3>
            </li>
            <li>
              <h3>Business Evaluations</h3>
            </li>
            <li>
              <h3>Franchise Agreements</h3>
            </li>
            <li>
              <h3>License Transfers</h3>
            </li>
            <li>
              <h3>Indemnification Agreements</h3>
            </li>
            <li>
              <h3>Vendor Agreements</h3>
            </li>
            <li>
              <h3>Purchase Terms and Conditions</h3>
            </li>
          </ul>
          <h2>
            Representing Businesses and Individuals in Business Law{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              Litigation
            </Link>
          </h2>
          <p>
            If your business or commercial dispute reaches an impasse, we can
            advocate and protect your interests in business and commercial
            litigation in cases involving:
          </p>
          <ul>
            <li>
              <h3>Breach of Contract</h3>
            </li>
            <li>
              <h3>Employment Disputes</h3>
            </li>
            <li>
              <h3>Business Sale Disputes</h3>
            </li>
            <li>
              <h3>
                <Link to="/practice-areas/south-carolina-real-estate-law">
                  Real Estate Disputes
                </Link>
              </h3>
            </li>
            <li>
              <h3>Landlord – Tenant Disputes</h3>
            </li>
            <li>
              <h3>Collections</h3>
            </li>
            <li>
              <h3>Partnership Disputes</h3>
            </li>
            <li>
              <h3>Zoning Disputes</h3>
            </li>
          </ul>
          <h2>
            Attorneys with Experience in{" "}
            <Link to="/practice-areas/south-carolina-transportation-law">
              Transportation Law
            </Link>
          </h2>
          <p>
            Our law practice also represents various transportation businesses.
            Our Transportation practice includes the representation of
            transportation providers, including motor carriers, vessel
            operators, stevedores and Class I railroads. Our attorneys have
            represented clients in such transportation business matters as:
          </p>
          <ul>
            <li>
              <h3>Vessel Construction Contracts</h3>
            </li>
            <li>
              <h3>Vessel Mortgages</h3>
            </li>
            <li>
              <h3>Vessel Documentation</h3>
            </li>
            <li>
              <h3>Vessel Charter Parties</h3>
            </li>
            <li>
              <h3>Trucking Agreements</h3>
            </li>
            <li>
              <h3>Equipment Purchase Agreements</h3>
            </li>
            ``
            <li>
              <h3>Shipping Contracts/Bills of Lading</h3>
            </li>
            <li>
              <h3>Marine Construction Contracts</h3>
            </li>
          </ul>
          <p>
            Whether you are just starting out and need help structuring your new
            business, or you have a long-standing business with complicated
            issues that need attention, our seasoned Business Lawyers can assist
            you in all of your legal matters.{" "}
            <Link to="/contact">Contact us</Link> to schedule a consultation.
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query BusinessLawSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "south-carolina-business-law" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
